import React, { useEffect, useRef } from "react";
import {
  FilterCheckboxContainer,
  FilterItem,
  FilterItemHeader,
  FilterItemHeading,
  FilterItemIconContainer,
  FilterModal,
  FilterModalBody,
  FilterModalHeader,
  FilterModalIconContainer,
} from "../styles";
import Icon from "../../../Components/Icons/icons";
import { KBCheckbox } from "../../../Components/CheckBox";
import { Box, Grid } from "@mui/material";
import MoreLink from "./MoreLink";
import SearchField from "../../../Components/SearchField";
import { ITagType } from "./types";

export interface FilterItemProps {
  heading: string;
  checkboxes: string[];
  type: string;
  checked: ITagType[];
  modalState: (a: boolean) => void;
  onChange: Function;
}

const FilterItemBox: React.FC<FilterItemProps> = ({
  heading,
  type,
  checkboxes,
  checked,
  modalState,
  onChange,
}) => {
  const moreLinkRef = useRef<HTMLElement>(null);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [overlayPosition, setOverlayPosition] = React.useState<DOMRect>();
  const [listItems, setListItems] = React.useState<string[]>([...checkboxes]);
  const [searchFilter, setSearchFilter] = React.useState<string>("");

  useEffect(() => {
    const lists = [...checkboxes];
    const filteredList = lists.filter((list) =>
      list.toLowerCase().includes(searchFilter.toLowerCase())
    );
    setListItems(filteredList);
  }, [searchFilter, checkboxes]);

  const getInitialCheckboxes = () => {
    const firstFourElements: string[] = checkboxes.slice(0, 5);

    return (
      <>
        {firstFourElements?.map((checkbox, index) => (
          <KBCheckbox
            checked={
              checked.find(
                (tag) => tag.name?.toLowerCase() === checkbox.toLowerCase()
              )
                ? true
                : false
            }
            onChange={(e: any) => onChange(e, checkbox, type)}
            key={index}
            label={checkbox}
          />
        ))}
      </>
    );
  };
  const getFilterCount = () => {
    const length = checkboxes?.length - 5;
    return length;
  };
  function handleClick() {
    setOverlayPosition(moreLinkRef.current?.getBoundingClientRect());
    setShowModal(!showModal);
    modalState(!showModal);
  }

  const closeModal = () => {
    setShowModal(false);
    modalState(false);
  };

  const handleSearch = (e: any) => {
    setSearchFilter(e.target.value);
  };

  return (
    <>
      <FilterItem>
        <FilterItemHeader>
          <FilterItemHeading title={heading} />
          {checkboxes.length > 5 && (
            <FilterItemIconContainer onClick={handleClick}>
              <Icon kind={"search"} size={24} color="#8F8F8F" />
            </FilterItemIconContainer>
          )}
        </FilterItemHeader>
        <FilterCheckboxContainer>
          {getInitialCheckboxes()}
        </FilterCheckboxContainer>
        {checkboxes.length > 5 && (
          <MoreLink
            moreLinkRef={moreLinkRef}
            label={`+${getFilterCount()}`}
            onClick={handleClick}
          />
        )}

        {showModal && (
          <>
            <FilterModal
              style={{
                top:
                  Number(overlayPosition?.top) -
                  (type === "customer_location" ? 370 : 240),
              }}
            >
              <FilterModalHeader>
                <Box width={240}>
                  <SearchField
                    value={searchFilter}
                    onChange={handleSearch}
                    placeholder={"Search input"}
                  />
                </Box>
                <FilterModalIconContainer onClick={closeModal}>
                  <Icon size={24} kind={"close"} />
                </FilterModalIconContainer>
              </FilterModalHeader>
              <FilterModalBody container>
                {listItems?.map((checkbox) => (
                  <Grid item xs={3} key={Math.random()}>
                    <KBCheckbox
                      checked={
                        checked.find((tag) => {
                          return (
                            tag.name?.toLowerCase() === checkbox.toLowerCase()
                          );
                        })
                          ? true
                          : false
                      }
                      onChange={(e: any) => onChange(e, checkbox, type)}
                      label={checkbox}
                    />
                  </Grid>
                ))}
              </FilterModalBody>
            </FilterModal>
          </>
        )}
      </FilterItem>
    </>
  );
};

export default FilterItemBox;
