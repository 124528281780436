import React from "react";
import {
  BlockHeading,
  PageText,
} from "./styles";

export const Overview = ({ data }: any) => {
  return (
    <>
      <BlockHeading title="Overview" />
      <PageText>{data?.synopsis}</PageText>
    </>
  );
};
