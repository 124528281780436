import { Box, CardMedia } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Icon from '../../Components/Icons/icons';

const YouTubeThumbnail = ({ url }: any) => {
    const [videoId, setVideoId] = useState<string | null>(null);
    const [videoThumbnail, setVideoThumbnail] = useState<string | null>(null);

    useEffect(() => {
        parseYouTubeURL(url);
    }, [url]);

    const parseYouTubeURL = (url: any) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        if (match && match[7].length === 11) {
            setVideoId(match[7]);
            const thumbnailUrl = `//img.youtube.com/vi/${match[7]}/0.jpg`;
            setVideoThumbnail(thumbnailUrl);
        } else {
            setVideoId(null);
            setVideoThumbnail(null);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
            {videoId ? (
                <>
                    {videoThumbnail && (
                        <CardMedia
                            sx={{ height: 145, width: '100%' }}
                            image={videoThumbnail}
                            title={url}
                        />
                    )}
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Icon kind={"youtube-play-button"} />
                    </Box>
                </>
            ) : (
                <p>Invalid YouTube URL</p>
            )}
        </Box>
    );
};

export default YouTubeThumbnail;
