import React, { createContext } from "react";

export interface UserContextType {
  user: boolean | null;
  setUser: React.Dispatch<React.SetStateAction<boolean | null>>;
}

// Create the context
export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
});
