import { Breadcrumbs } from "@mui/material";
import React, { useRef, useState } from "react";
import { useLocation, Link, useSearchParams } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");

  React.useEffect(() => {
    const searchValue = searchParams.get("query") || "";
    const type = searchParams.get("type") || "";
    type?.length ? setSearchQuery(searchValue) : setSearchQuery("SEARCH");
    if (type?.length) {
      setSearchQuery(searchValue);
    }
  }, [searchParams]);
  return (
    <>
      {location.pathname === "/search" ? (
        <Breadcrumbs separator={<>&nbsp;{">"}&nbsp;</>} aria-label="breadcrumb">
          <Link to="/" className="active">
            Home
          </Link>
          {searchQuery ? (
            <Link to="/" className="inactive">
              {searchQuery}
            </Link>
          ) : (
            ""
          )}
        </Breadcrumbs>
      ) : (
        <Breadcrumbs separator={<>&nbsp;{">"}&nbsp;</>} aria-label="breadcrumb">
          {location.pathname !== "/" &&
            location.pathname.split("/").map((item, i) => (
              <Link
                key={i}
                to={item === "" ? "/" : "/" + item}
                className={
                  location.pathname.split("/").length - 1 === i
                    ? "inactive"
                    : "active"
                }
              >
                {item === ""
                  ? "Home"
                  : decodeURIComponent(item).split("-").join(" ")}
              </Link>
            ))}
        </Breadcrumbs>
      )}
    </>
  );
};

export default Breadcrumb;
