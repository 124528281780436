import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { Heading } from "../../Components/Heading";
import { theme } from "../../theme";
export const PageWrapper = styled(Box)(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

export const BottomSection = styled(Box)(() => ({
  backgroundColor: "#fff",
  flexGrow: 1,
  paddingTop: "2.188rem",
  paddingBottom: "0rem",
  overflow: "hidden",
}));

export const CalloutCardContent = styled(Box)(() => ({
  flexBasis: "100%",
  paddingBlock: "0.5rem",
  textAlign: "center",
  borderRight: "1px solid #fff",
  background: "#F4F4F9",
  borderBottom: "0.125rem solid #F4F4F9",
  cursor: "pointer",
  "&.active": {
    backgroundColor: "#E8F5F3",
    borderBottom: "0.125rem solid #0B93DF",
    "& p": {
      "&:nth-child(2)": {
        color: "#0B93DF",
      },
    },
  },
  "&:first-child": {
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
  },
  "&:last-child": {
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
}));

export const CalloutCardValue = styled(Heading)(() => ({
  color: "#343434",
  fontSize: "1.5rem",
  lineHeight: 1.167,
  fontWeight: 500,
  marginBottom: ".25rem",
}));
export const CalloutCardSubHeading = styled(Heading)(() => ({
  color: "#555555",
  fontSize: ".875rem",
  lineHeight: 2,
  fontWeight: 500,
  borderBottom: "2px solid transparent",
  width: "fit-content",
  margin: "0 auto",
}));

export const TableContainer = styled(Box)(() => ({
  flexBasis: "100%",
  paddingBlock: "1rem 1.625rem",
  height: "calc(100% - 6.25rem)",
  background: "#F8F8F8",
  overflow: "auto",

  "& .table-row": {
    alignItems: "center",
    "& .MuiCardMedia-root": {
      height: "4.875rem",
    },
    "& .textPrimary": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
}));

export const TableHeader = styled(Box)(() => ({
  color: "#8F8F8F",
  fontSize: "0.875rem",
  fontWeight: 400,
  paddingInline: "1.25rem ",
}));

export const TableCell = styled(Box)(() => ({
  color: "#555",
  fontSize: "0.875rem",
  fontWeight: 400,
  padding: "1.25rem ",
  lineHeight: 1.14,
  // height: "100%",
}));

export const TableGridRow = styled(Grid)(() => ({
  background: "#fff",
  marginTop: "1rem",
  borderRadius: "0.375rem",
}));
export const TitleWithIcon = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  paddingInlineEnd: "1.25rem",
  alignItems: "center",

  "& .MuiBox-root": {
    fontWeight: 500,
    color: "#0B93DF",
  },
}));
