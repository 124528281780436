import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

interface ICircularLoader {
  size?: number;
}

const KbCircularLoader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const CircularLoader = ({ size }: ICircularLoader) => {
  return (
    <KbCircularLoader>
      <CircularProgress size={size} />
    </KbCircularLoader>
  );
};
