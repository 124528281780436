import React, { useContext } from "react";
import { Header } from "../../Components/Header";
import RouteConfig from "../../Routes/RouteConfig";
import { Box } from "@mui/material";
import { UserContext, UserContextType } from "../../Context/UserContext";

const RootApp = () => {
  const { user } = useContext<UserContextType>(UserContext);
  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      {user ? <Header isBreadcrumb={true} isSearch={true} /> : ""}
      <RouteConfig />
    </Box>
  );
};

export default RootApp;
