import { ITagType } from "../Containers/Search/Components/types";

export function convertExpertiseToNumber(value: string) {
  switch (value) {
    case "one":
      return 1;
    case "two":
      return 2;
    case "three":
      return 3;
    case "four":
      return 4;
    case "five":
      return 5;
    default:
      return 0;
  }
}

export const getDuration = (startDate: Date, endDate: Date) => {
  return new Date((endDate ? new Date(endDate) : new Date()).valueOf() - new Date(startDate).valueOf());
};

export const getMonthString = (monthNumber: number) => {
  switch (monthNumber) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sept";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
  }
};

export const areArraysOfObjectsEqual = (arr1: Array<ITagType>, arr2: Array<ITagType>) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = JSON.stringify(
    arr1.sort((a: ITagType, b: ITagType) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1))
  );
  const sortedArr2 = JSON.stringify(
    arr2.sort((a: ITagType, b: ITagType) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1))
  );
  return sortedArr1 === sortedArr2;
};

export const getFiltersForTypes = (allFilters: ITagType[], type: string) => {
  return allFilters.filter((tag: ITagType) => tag.type === type);
};

export const filterTypes: any = {
  business_tags: "industry",
  tech_tags: "technology",
  customer_engagement_types: "engagement",
  current_stage: "Current Stage",
  level_funding_now: "funding",
  customer_location: "location",
  customer_compliances: "Customer Compliances",
};

export const sortFilterByCheckedFilters = (filters: string[], checkedFilters: ITagType[], type: string) => {
  const newFiltersArr = filters?.map((f: any) => {
    const ckFilter = checkedFilters.find((c: any) => {
      return c.name === f.toLowerCase();
    });
    return {
      name: f,
      type: type,
      checked: ckFilter ? true : false,
    };
  });
  newFiltersArr?.sort((a: any, b: any) => b.checked - a.checked);

  return newFiltersArr?.map((n) => n.name);
};
