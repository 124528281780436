import { Box, Chip, Grid, IconButton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Heading } from "../../Components/Heading";
import Icon from "../../Components/Icons/icons";

export const KbSearchPageContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,

  overflow: "hidden",

  backgroundColor: "#fff",
  display: "flex",
  "& .noDataText": {
    textAlign: "center",
  },
}));

export const PageWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const Filter = styled(Box)(() => ({
  width: "18%",
  boxShadow: ".125rem 0 .375rem 0 #0000001F",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

export const FilterHeader = styled(Box)(() => ({
  borderBottom: "0.375rem solid #f8f8f8",
  paddingBlock: "2.25rem 1.875rem",
  paddingInline: "2rem",
  display: "flex",
  justifyContent: "space-between",
}));

export const FilterHeading = styled(Heading)(({ theme }) => ({
  fontSize: "1rem",
  flexGrow: 1,
  paddingRight: 2,
  color: theme.palette.text.primary,
  marginBottom: 0,
}));

export const FilterItemHeading = styled(FilterHeading)(({ theme }) => ({
  '&:first-letter':{
    textTransform:"uppercase"
  }
}));

export const ResetLink = styled("a")(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.primary.main,
  textDecoration: "underline",
  flexShrink: 0,
  cursor: "pointer",
}));

export const MoreLinkStyles = styled(ResetLink)(() => ({
  textDecoration: "none",
}));

export const FilterContent = styled(Box)(() => ({
  // boxShadow: '.125rem 0 .375rem 0 #0000001F',
  position: "relative",
  flexGrow: 1,
  paddingBlock: "2.25rem 1.875rem",
  paddingInline: "2rem",
  overflowY: "auto",
}));

export const FilterSectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
});

export const FilterItem = styled(FilterSectionContainer)({
  gap: "1.5rem",
  position: "relative",
});

export const FilterItemHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const FilterItemIconContainer = styled(IconButton)({
  background: "#EEEEEE",
});

export const FilterCheckboxContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});
export const FilterModal = styled(Box)({
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  background: "#fff",
  zIndex: 99,
  width: "55%",

  // bottom: 0,
  // left: ' 10%',
  minWidth: "46.875rem",
  borderRadius: "0.5rem",
  boxShadow: "0 0 .375rem rgba(0, 0, 0, 0.25)",
});
export const FilterModalIconContainer = styled(IconButton)({
  background: "#FFF",
  // display:'flex'
});
export const FilterModalHeader = styled(FilterItemHeader)({
  padding: "0.75rem 2rem",
  borderBottom: "1px solid rgba(222, 222, 237, 1)",
});
export const FilterModalBody = styled(Grid)({
  justifyContent: "flex-start",
  rowGap: "1rem",
  padding: "1rem 2rem",
  overflowY: "auto",
  maxHeight: "18.75rem",
});
export const MainContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

export const CustomerLogo = styled("img")(() => ({
  marginRight: "1.5rem",
  flexShrink: 0,
  objectFit: "contain",
  width: "4.3125rem",
  height: "4.3125rem",
  border: "1px solid #DEDEED",
  borderRadius: "0.375rem",
  padding: '0.25rem',
}));

export const CustomerName = styled(Heading)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 500,
  color: '#343434',
  marginBottom: ".5rem",
  lineHeight: 1.149,
}));

export const CustomerInfo = styled(Heading)(() => ({
  fontSize: ".875rem",
  fontWeight: 400,
  color: "#888",
  marginBottom: 0,
  lineHeight: 1.142,
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  whiteSpace: "initial",
}));

export const CustomerKeyOutcomes = styled(Heading)(() => ({
  fontSize: ".75rem",
  fontWeight: 400,
  color: "#555",
  marginBottom: 0,
  lineHeight: 1.14375,
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  whiteSpace: "initial",
}));

export const CustomerEngagementTerm = styled(Heading)(({ theme }) => ({
  fontSize: ".875rem",
  fontWeight: 400,
  color: '#555',
  marginBottom: 0,
  lineHeight: 1.14375,
}));

export const Label = styled(Heading)(() => ({
  fontSize: ".875rem",
  lineHeight: 1.142,
  color: "#888",
  marginBottom: 0,
}));

export const CustomerType = styled(Label)(() => ({
  fontWeight: 400,
  color: "#555555",
  marginBottom: 0,
  flexWrap: "wrap",
  fontSize: '.75rem',
  lineHeight: 1.5,
}));

export const CustomerInfoHeading = styled(Heading)(() => ({
  fontSize: ".875rem",
  color: "#8f8f8f",
  lineHeight: 1.142,
  marginBottom: "1rem",
  fontWeight: 400
}));

export const UndisclosedText = styled(Heading)(() => ({
  fontSize: ".875rem",
  fontWeight: 400,
  color: "#E88A0F",
  lineHeight: 1.142,
  marginBottom: 0,
  display: "flex",
  flexShrink: 0,
}));

export const NoResultsText = styled(Heading)(() => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: "#555",
  lineHeight: 1.5,
  marginBottom: 0,
  marginTop: ".75rem",
}));

export const QueryWord = styled(NoResultsText)(() => ({
  fontWeight: 400,
  color: "#8f8f8f",
  marginTop: 0,
}));

export const CustomerStatus = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status?: boolean }>(
  ({ status }) => `
  padding: .375rem .28125rem;
  font-size: .625rem;
  color: #fff;
  height: 1.4375rem;
  font-weight: 500;
  background-color: ${status ? "#11B868" : "#F0AD56"};
  border-color: ${status ? "#11B868" : "#F0AD56"};
  &:hover{
    background-color: ${status ? "#11B868" : "#F0AD56"};
    border-color: ${status ? "#11B868" : "#F0AD56"};
  }
`
);

export const InfoIcon = styled(Icon)(() => ({
  marginRight: 5,
}));

export const ListItem = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: ".25rem",
  boxShadow: "none",
  marginBottom: "1rem",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 0 .25rem 0 #0000001F",
  },
}));

export const NoDataFoundWrapper = styled(Box)(() => ({
  backgroundColor: "#F8F8F8",
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

export const CalloutCard = styled(Box)(() => ({
  borderRadius: ".75rem",
  backgroundColor: "#F5F5FF",
  marginBlock: "1.5rem",
}));

export const CalloutCardHeading = styled(Heading)(() => ({
  color: "#444",
  fontSize: ".875rem",
  lineHeight: 2,
  paddingBlock: ".28125rem",
  paddingInline: ".9375rem",
  textTransform: "capitalize",
  fontWeight: 500,
  borderBottom: "1px solid #DEDEED",
  marginBottom: 0,
}));

export const CalloutCardValue = styled(Heading)(() => ({
  color: "#444",
  fontSize: "1.5rem",
  lineHeight: 1.167,
  fontWeight: 500,
  marginBottom: ".25rem",
}));

export const CalloutCardSubHeading = styled(Heading)(() => ({
  color: "#8F8F8F",
  fontSize: ".875rem",
  lineHeight: 2,
  fontWeight: 500,
  borderBottom: "2px solid transparent",
  width: "fit-content",
  margin: "0 auto",
}));

export const CustomerLocation = styled(Heading)(() => ({
  margin: 0,
  marginLeft: '0.25rem',
  fontSize: ".875rem",
  color:"#555",
  fontWeight: 400
}));

export const CalloutCardContent = styled(Box)(() => ({
  flexBasis: "100%",
  paddingBlock: "1rem",
  textAlign: "center",
  cursor: "pointer",
  borderRight: "1px solid #DEDEED",
  "&:hover": {
    backgroundColor: "rgb(222 222 237 / 50%)",
  },
}));

export const KBTagsStack = styled(Stack)(({ theme }) => ({
  flexWrap: "wrap",
  marginInlineStart: "-1rem",
  marginBlockEnd: "-1rem",
  "&>:not(style)~:not(style)": {
    margin: "unset",
    marginInlineStart: "1rem",
    marginBlockEnd: "1rem",
  },
}));

export const KBTags = styled(Box)(({ theme }) => ({
  padding: "1.25rem 0.9375rem",
  borderBottom: "1px solid #DBDBDB",
  maxHeight:"12.5rem",
  minHeight:"12.5rem",
  overflow:"auto",
  display:"flex",
  flexDirection:"column"
}));

export const KBChips = styled(Chip)(({ theme }) => ({
  marginInlineStart: "1rem",
  marginBlockEnd: "1rem",
  textTransform: "uppercase",
}));

export const TeamLabel = styled(Label)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  marginLeft: '0.25rem',
  color: '#555',
}));

export const CurrentStage = styled(Label)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  marginLeft: '0.25rem',
  color: '#555',
}));

export const CurrentStageValue = styled(Box)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: 1.2,
  color: '#555'
}));

export const NDAApproval = styled(Label)(({ theme }) => ({
  color: '#11B868',
  fontWeight: 400 
}));

export const CustomerEngagementWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  paddingLeft: '1.75rem',
  alignItems: 'center',
}));
 
export const CustomerStatusBox = styled(Box)(({ theme }) => ({
  marginInline: '1rem',
  marginTop: '1rem',
  paddingBottom: '1rem',
  borderTop: "1px solid #DEDEED",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
}));

 
export const CurrentStageBox = styled(Box)(({ theme }) => ({
  paddingRight: '1rem',
  paddingLeft: '0.5rem',
  display: "flex",
  alignItems: "center",
  flexGrow: '1',
}));