import { MoreLinkStyles } from "../styles";

const MoreLink = ({
  label,
  onClick,
  moreLinkRef,
}: {
  label: string;
  onClick?: () => void;
  moreLinkRef: any;
}) => {
  return (
    <MoreLinkStyles ref={moreLinkRef} onClick={onClick}>
      {label}
    </MoreLinkStyles>
  );
};

export default MoreLink;
