import { useState, useEffect } from 'react';
import { axiosInstance } from '../AxiosInstance';

export type IApiResponse = {
  data: any;
  error: any;
  loading: boolean;
};

export const useFetch = (url: string): IApiResponse => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  
  useEffect(() => {
    const getAPIData = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(url);
        setData(res?.data?.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    getAPIData();
  }, [url]);

  return { data, error, loading };
};
