import styled from "@emotion/styled";
import { Avatar, Box, Button, Chip, Link, Menu, Tab, Tabs, Typography } from "@mui/material";
import Icon from "../../Components/Icons/icons";
import { Heading } from "../../Components/Heading";


export const CustomerStatus = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status?: boolean }>(
  ({ status }) => `
  padding: .375rem .28125rem;
  font-size: .625rem;
  color: #fff;
  height: 1.4375rem;
  background-color: ${status ? "#11B868" : "#F0AD56"};
  border-color: ${status ? "#11B868" : "#F0AD56"};
  width:fit-content;
  align-self:center;
  transform: translateY(-50%);
  &:hover{
    background-color: ${status ? "#11B868" : "#F0AD56"};
    border-color: ${status ? "#11B868" : "#F0AD56"};
  }
`
);

export const CustomerInfoLabel = styled(Heading)(() => ({
  fontSize: "1rem",
  color: "#343434",
  fontWeight: 400,
  lineHeight: 1.21,
  marginBottom: ".35rem",

}));
export const CustomerInfoValue = styled(Heading)(() => ({
  fontSize: "0.875rem",
  color: "#555",
  lineHeight: 1.142,
  marginBottom: "1rem",
  fontWeight: 400,
}));

export const CustomerName = styled(Heading)(() => ({
  fontSize: "1.25rem",
  fontWeight: 500,
  color: "#555",
  marginBottom: ".5rem",
  lineHeight: 1.2,
}));

export const CustomerLogo = styled("img")(() => ({
  flexShrink: 0,
  objectFit: "contain",
  width: "5.25rem",
  height: "5.25rem",
  border: '1px solid #DEDEED',
  borderRadius: '0.625rem',
  padding: '0.25rem',
}));

export const PageWrapper = styled(Box)(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}));

export const UndisclosedText = styled(Heading)(() => ({
  fontSize: ".875rem",
  fontWeight: 400,
  color: "#E88A0F",
  lineHeight: 1.142,
  marginBottom: 0,
  display: "flex",
  flexShrink:0
}));

export const InfoIcon = styled(Icon)(() => ({
  marginRight: 5,
}));


export const TopSection = styled(Box)(() => ({
  paddingBlockStart: "2rem",
  paddingInline: "1rem",
  paddingBlockEnd: 0,
  backgroundColor: "#F8F8F8",
  minHeight: "245px",
  flexShrink: 0,
}));

export const BottomSection = styled(TopSection)(() => ({
  backgroundColor: "#fff",
  flexGrow: 1,
  paddingTop: "1.875rem",
  paddingBottom: "2rem",
}));

export const KeyOutcomes = styled(Box)(() => ({
  backgroundColor: "#fff",
  flexGrow: 1,
  padding: "1rem 0",
  borderBlock: "1px solid #DEDEED",
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: '1.87rem',
  marginTop: '1.5rem',
}));

export const KeyCustomerTags = styled(Box)(() => ({
  backgroundColor: "#F5F5F5",
  padding: ".375rem 1.5rem",
  position: "relative",
  borderRadius: ".375rem",
  display: "flex",
  alignItems: "center",
  color: "#555555",
  fontSize: ".875rem",
  lineHeight: 1.7142,
  marginRight: ".625rem",
  marginBottom: ".625rem",
  fontWeight: 400
}));

export const IconKeyOutcomes = styled(Icon)(() => ({
  flexShrink: 0
}));

export const KeyOutcomesLabel = styled(Heading)(() => ({
  fontSize: "1.25rem",
  lineHeight: 1.15,
  paddingRight:".5rem",
  color: "#0B93DF",
  marginBottom: 0,
  fontWeight: 500,
  flexShrink: 0,
}));

export const NoResultsText = styled(Heading)(() => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: "#555",
  lineHeight: 1.5,
  marginBottom: 0,
  marginTop: ".75rem",
}));

export const QueryWord = styled(NoResultsText)(() => ({
  fontWeight: 400,
  color: "#8f8f8f",
  marginTop: 0,
}));

export const KeyOutcomesValue = styled(KeyOutcomesLabel)(() => ({
  fontWeight: 400,
  marginLeft: 0,
  marginRight: 0,
  padding: 0,
  flexGrow:1,
  flexBasis: 0,
  fontSize: '0.875rem',
  color: '#555555',
}));

export const SectionHeading = styled(Heading)(() => ({
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: 1.149,
  color: "#343434",
  marginBottom: ".25rem",
}));

export const WowFactorsHeading = styled(SectionHeading)(() => ({
  marginBottom: "1rem",
}));

export const PageText = styled(Typography)(() => ({
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: "#555",
}));

export const BlockHeading = styled(Heading)(() => ({
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: 1.149,
  color: "#343434",
  marginBottom: "0.125rem",
}));


export const NoDataFoundWrapper = styled(Box)(() => ({
  backgroundColor:'#F8F8F8',
  flexGrow:1,
  display:"flex",
  alignItems:"center",
  justifyContent:'center',
  flexDirection:"column"
}));

export const CustomerLocation = styled(Heading)(() => ({
  margin:0,
  fontSize: ".875rem",
  color:"#555",
  fontWeight: 400
}));

export const ShowcaseDropdownValue = styled(Heading)(() => ({
  margin:0,
  fontSize: "1rem",
  color:"#343434",
  fontWeight: 500,
  lineHeight: '1.125',
  marginLeft: '1rem',
}));

export const ShowcaseIcon = styled(Icon)(() => ({
  marginRight: 5,
  color: '#0B93DF',
}));

export const ShowcaseIconArrow = styled(Icon)(() => ({
  transition: 'all 1s linear',
}));

export const ShowcaseDropdownWrapper = styled(Box)(() => ({
    "&.is-disabled": {
      color: '#AFAFAF',
      pointerEvents: 'none',
    "& svg": {
        color: '#AFAFAF',
    },
    "& p": {
      color: '#AFAFAF',
    },
    
  }
}));

export const ShowcaseDropdownButton = styled(Button)(() => ({
  backgroundColor: '#fff',
  boxShadow: "0px 0px 4px 0px #00000040",
  borderRadius: '0.375rem',
  padding: "0.5rem 0.75rem",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  textTransform: 'none',
  "&.is-open": {
    background: '#F5F5F5',
  }
}));

export const ShowcaseDropdownMenu = styled(Menu)(() => ({
  
  "& .MuiMenu-paper": {
    width: '850px',
    marginTop: '0.25rem',
  },
  "&.clutch-review": {
      "& .MuiMenu-paper": {
        left: '265px !important',
      },
  }
}));

export const ShowCaseButton = styled("button")(() => ({
  padding:'8px 14px 8px 7px',
  backgroundColor:'#fff',
  boxShadow: "0 0 .25rem 0 #00000040",
  border:0,
  width:'100%',
  textAlign:'left',
  display:'flex',
  alignItems:"center",
  borderRadius:"6px",
  cursor:"pointer",
  '&.activeCaseStudy, &.activeClutchReview, &.activeTestimonial':{
    backgroundColor:'#F5F5F5'
  },
  "& svg": {
    color: '#0B93DF',
  },
  '&:disabled':{
    cursor:'default',
    "& svg, & span": {
      color: '#AFAFAF',
    },
  
  }
}))

export const ShowCaseButtonText = styled("span")(() => ({
  fontSize:'1rem',
  fontWeight: 500,
  color:'#343434',
  marginLeft:'1rem',
  lineHeight:1.125,
  fontFamily:'Ubuntu,sans-serif'
}))

export const NoInformationText = styled("div")(() => ({
  color: '#8F8F8F',
  fontSize: '0.875rem',
  fontWeight: 400,
  marginLeft: '0rem',
  lineHeight: '1.71',
  fontFamily:'Ubuntu,sans-serif'
}))
export const SidebarNoInformationText = styled(NoInformationText)(() => ({
  marginLeft:"2.5rem"
}))

export const CaretIcon = styled(Icon)(() => ({
  marginLeft:"auto",
  transition:'all 0.5s ease',
  '&.rotate-showcase':{
    transform:'rotate(180deg)',  
  }
}))
export const CaretIconRotate = styled(CaretIcon)(() => ({
  transform:'rotate(180deg)',
}))

export const CustomerShowcaseContent = styled(Box)(() => ({
  marginTop:'7px',
  borderRadius:'6px',
  padding:"22px 16px",
  boxShadow:"0 0 4px 0 #00000040",
  transition:'all 0.5s ease'
}))

export const CustomerShowcaseContentWrapper = styled(Box)(() => ({
 marginBottom:'1rem',
 '&:last-child':{
  marginBottom: 0
 }
}))


export const ContributionHeadingBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  // borderBottom: '#DEDEED',
  alignItems: 'flex-end',
  borderBottom: '1px solid #DEDEED',
}))

export const KbTab = styled(Tabs)(() => ({
  minHeight: 'inherit',
  '& .MuiTabs-indicator': {
    display: 'none',
  }
}))

export const KbTabItem = styled(Tab)(() => ({
    fontSize: '14px',
    paddingInline: '0.75rem',
    paddingBlock: '0.5rem',
    border: '1px solid #DEDEED',
    borderBottom: 0,
    borderRadius: '6px 6px 0 0',
    minHeight: '2rem',
    marginRight: '0.75rem',
    color: '#555',
    transition: 'all 0.2s ease-in-out',
    textTransform: 'capitalize',
    fontWeight: '400',
    '&:last-child': {
      marginRight: '0rem',
    },
    '&:hover': {
      color: '#fff',
      opacity: 1,
      backgroundColor: '#0B93DF',
      transition: 'all 0.2s ease-in-out',
    },
    '&.Mui-selected': {
      backgroundColor: '#0B93DF',
      color: '#fff',
      transition: 'all 0.2s ease-in-out',
      borderColor: '#0B93DF',
      pointerEvents: 'none',
      cursor: 'default',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
  },
  '&.Mui-disabled': {
    backgroundColor: '#F5F5F5',
    color: '#AFAFAF',
    cursor: 'not-allowed',
    },
  }),
);

export const NotificationContainer = styled(Box)(() => ({
  boxShadow: "0px 0px 6px 0px #00000025",
  borderRadius: '0.75rem',
  padding: 0,
  overflow: 'hidden'
}))
  
export const NotificationHeader = styled(Typography)(() => ({
  background: '#F1F1F8',
  color: '#555555',
  fontSize: '1rem',
  lineHeight: 1.14,
  padding: '1.18rem 0.75rem',
  fontWeight: '500',
}))

export const NotificationSubHeader = styled(Typography)(() => ({
  color: '#555555',
  fontSize: '1rem',
  lineHeight: 1.14,
  padding: '1.18rem 0.75rem',
  fontWeight: '500',
  borderBottom: '1px solid #DEDEED',
}))
  
export const ShowcaseWrapper = styled('div')(() => ({
  padding: '1.31rem 0.75rem',
}))

export const ShowCaseTitle = styled(Typography)(() => ({
  display:'flex',
  alignItems:"center",
  marginBottom: '0.57rem',

  "& svg": {
    color: '#0B93DF',
  },
  '&:disabled':{
    cursor:'default',
    "& svg, & span": {
      color: '#AFAFAF',
    },
  }
}))

export const ShowCaseLink = styled(Link)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#0B93DF",
  paddingBlock: '0.25rem',
  paddingLeft:"2.5rem",
  "&:hover": {
    textDecoration: "underline",
  },
}))

export const ShowCaseLinkImage = styled(ShowCaseLink)(() => ({
  paddingLeft: 0,
}))

export const KBAvatar = styled(Avatar)(() => ({
  width: '2rem',
  height: '2rem',
  backgroundColor: '#DEDEDE',
  color: '#555555',
  fontSize: '1rem',
  "&.MuiAvatar-circular": {
    backgroundColor: '#DEDEED',
  }
}))

export const StakeHolderName = styled(Typography)(() => ({
  fontSize: "0.875rem",
  lineHeight: 1.15,
  color: "#555",
  marginBottom: '0.25rem',
}))

export const StakeHolderDesignation = styled(Typography)(() => ({
  fontSize: "0.625rem",
  lineHeight: 1.15,
  color: "#555",
  fontWeight: '400',
  marginBottom: '0.25rem',
}))

export const StakeHolderEmail = styled(Typography)(() => ({
  fontSize: "0.625rem",
  lineHeight: 1.15,
  color: "#0B93DF",
  fontWeight: '400',
  "&:hover": {
    textDecoration: "underline",
  },
}))

export const StakeHolderReportsTo = styled(StakeHolderDesignation)(() => ({
}))

export const StakeHolderListItem = styled(Box)(() => ({
  display: 'flex',
  paddingBlock: '0.68rem',
  alignItems: 'center',
  "&:first-of-type": {
    paddingTop: '0',
  },
  "&:last-child": {
    paddingBottom: '0',
  }
}))

export const WowFactorsItemBox = styled(Box)(() => ({
  marginBottom: '1rem',
  "& ul": {
    paddingLeft: '1rem',
  }
}))


export const CustomerInsightContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "separator",
})<{ separator?: boolean }>(
  ({ separator }) => `
  flex-wrap: flex;
  font-size: wrap;
  color: #fff;
  padding-bottom: 1.5rem;
  padding-top: 0.625rem;
  margin-bottom: ${separator ? "1rem" : 0};
  border-bottom: ${separator ? "1px solid #DEDEED" : 0};
`
);

export const CustomerToolsAndCompliancesContent = styled(CustomerInsightContent, {
  shouldForwardProp: (prop) => prop !== "separator",
})<{ separator?: boolean }>(
  ({ separator }) => `
  padding-bottom: 0.5rem;
`
);
