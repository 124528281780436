import React from "react";
import { BlockHeading, NoInformationText } from "./styles";
import { Box } from "@mui/material";

export const TeamSize = ({ data }: any) => {
  return (
    <Box
      marginBottom={"1rem"}
      paddingBottom={"1rem"}
      borderBottom={"1px solid #DEDEED"}
    >
      <BlockHeading title="Talentica team" />
      {data?.team_size !== null ? (
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          paddingTop={"0.625rem"}
          color={"#555"}
          fontSize={"0.87rem"}
        >
          {data?.team_size}{" "}
          {data?.team_size === 1 || data?.team_size === 0
            ? "Member"
            : "Members"}
        </Box>
      ) : (
        <Box paddingTop={"0.625rem"}>
          <NoInformationText>0 Member</NoInformationText>
        </Box>
      )}
    </Box>
  );
};
