import React from "react";
import { Box } from "@mui/material";
import { BlockHeading, CustomerInsightContent, NoInformationText, PageText } from "./styles";

export const CustomerCompetitorsAndTargetRegions = ({ data, title }: any) => {
    return (
        <>
            <BlockHeading title={title} />
            <CustomerInsightContent>
                {data?.data.length !== 0 ? (

                    <Box display={"flex"} flexWrap={"wrap"}>
                        {data?.data.map((customer: any, index: any) => (
                            <PageText display={'flex'} key={customer.id}> {customer.attributes.name}{index !== data.data.length - 1 ? <PageText paddingRight={'4px'}>,</PageText> : ''}</PageText>
                        ))
                        }
                    </Box>
                )
                    :
                    <NoInformationText>Information not available</NoInformationText>
                }
            </CustomerInsightContent>
        </>
    );
};