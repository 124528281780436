// helper.ts
interface ResourceTableProps {
  activeTab: string;
  headerColumns: TableColumn[];
}

interface TableColumn {
  name: string;
  width: number;
}

interface LastColumnData {
  type: "link" | "video";
  title: string;
  link?: string;
}

export const getTableColumns = (activeTab: string): { name: string; width: number; field: string }[] => {
  switch (activeTab) {
    case "customer-case-studies":
      return [
        { name: "Published Date", width: 2, field: "published_date" },
        { name: "Customer Name", width: 3, field: "customer_name" },
        { name: "Suitable For", width: 3, field: "suitable_for" },
        { name: "Title", width: 3, field: "title" },
        { name: "Link", width: 1, field: "link" },
      ];

    case "customer-external-publications":
      return [
        { name: "Published Date", width: 2, field: "published_date" },
        { name: "Category", width: 2, field: "category" },
        { name: "Topic Category", width: 2, field: "topic_category" },
        { name: " Topic", width: 2, field: "topic" },
        { name: "Tier", width: 1, field: "tier" },
        { name: "Author", width: 2, field: "author" },
        { name: "Link", width: 1, field: "link" },
      ];

    case "customer-tl-articles":
      return [
        { name: "Published Date", width: 2, field: "published_date" },
        { name: "Topic Category", width: 2, field: "topic_category" },
        { name: "Subject", width: 5, field: "topic" },
        { name: "Author", width: 2, field: "author" },
        { name: "Link", width: 1, field: "link" },
      ];

    case "customer-pitch-decks":
      return [
        { name: "Published Date", width: 2, field: "published_date" },

        { name: "Topic", width: 8, field: "title" },
        { name: "Link", width: 2, field: "link" },
      ];

    case "customer-clutch-reviews":
      return [
        { name: "Published Date", width: 2, field: "published_date" },
        { name: "Customer Name", width: 3, field: "customer_name" },
        { name: "Stakeholder’s Name", width: 3, field: "stakeholder_name" },
        { name: "Designation", width: 3, field: "designation" },
        { name: "Link", width: 1, field: "link" },
      ];

    case "customer-testimonials":
      return [
        { name: "Published Date", width: 2, field: "published_date" },
        { name: "Customer Name", width: 3, field: "customer_name" },
        { name: "Stakeholder's Name", width: 2, field: "stakeholder_name" },
        { name: "Designation", width: 3, field: "designation" },
        { name: "Testimonial", width: 2, field: "link" },
      ];

    default:
      return [];
  }
};
