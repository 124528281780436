import styled from '@emotion/styled';
import { FormControl, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface SearchFieldProps {
  id?: string;
  label?: string;
  placeholder: string;
  focus?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormHelperText-root': {
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#fff',
  },
  '&.MuiOutlinedInput-root': {
    border: '1px solid rgba(255, 255, 255, 1)',
  },
}));

const SearchField: React.FC<SearchFieldProps> = ({ label, placeholder, id, focus, onChange, value }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  return (
    <FormControl>
      <StyledTextField
        fullWidth
        id={id}
        label={label}
        placeholder={placeholder}
        size='small'
        variant='outlined'
        value={value}
        onChange={onChange}
        autoFocus={focus}
      />
    </FormControl>
  );
};

export default SearchField;
