import { useContext } from "react";
import { AppBar, Box, Button } from "@mui/material";
import { SearchBox } from "../SearchBox";
import Breadcrumb from "../Breadcrumb";
import Icon from "../Icons/icons";
import Logo from "../../Assets/images/talentica_logo.svg";
import { styled } from "@mui/material/styles";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { UserContext, UserContextType } from "../../Context/UserContext";

interface IHeader {
  isSearch?: boolean;
  isBreadcrumb?: boolean;
}
const KbHeader = styled(AppBar)(() => ({
  paddingBlock: ".7840625rem",
  boxShadow: "0 .25rem .25rem 0 rgba(0,0,0,.4)",
  "& .btn-logout": {
    color: "#000",
    "&:hover": {
      "& svg": {
        fill: "#0B93DF",
      },
      color: "#0B93DF",
    },
  },
  "& .logo": {
    display: "flex",
    alignItems: "center",
  },
  "& .headerWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .headerRight": {
      display: "flex",
      gap: "1rem",
      alignItems: "center",
    },
  },
  "& .header-search-box": {
    maxWidth: "100%",
    width: "23.25rem",
    "& .MuiFormHelperText-root": {
      fontSize: "8px",
    },
    "& .MuiButtonBase-root": {
      paddingInline: "0.938rem",
    },
    "& .MuiInputBase-root": {
      height: "2.25rem",
      "& .MuiInputBase-input": {
        height: "2.25rem",
        boxSizing: "border-box",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.75rem",
    },
  },
}));

export const Header = ({ isBreadcrumb }: IHeader) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("query");
  const { setUser } = useContext<UserContextType>(UserContext);

  const logOut = () => {
    setUser(false);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <KbHeader color="inherit" position="sticky">
      <Box className="headerWrapper">
        <Box className="logo">
          <Link to="/">
            <img width={150} height={50} src={Logo} alt="talentica" />
          </Link>
          {isBreadcrumb && <Breadcrumb />}
        </Box>
        <Box className="headerRight">
          {location.pathname !== "/" && (
            <SearchBox
              id="search"
              labelText="Search"
              onlySearchIcon={true}
              className="header-search-box"
              showDropdown={false}
              value={
                searchValue && location.pathname === "/search"
                  ? searchValue
                  : ""
              }
            />
          )}
          <Button
            className="btn-logout"
            onClick={logOut}
            startIcon={<Icon size={16} kind="logout" />}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </KbHeader>
  );
};
